import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import cx from 'classnames';
const IFrame = dynamic(() => import('@/components/IFrame'));

export default function IDonateForm({ campaignId, designationId, embedId }) {
    const router = useRouter();
    const [safariBrowser, setIsSafariBrowser] = useState(false);
    const asPath = router?.asPath;
    const params = asPath.substring(asPath.indexOf('?'));
    const { designation, reference_code } = router?.query;
    const noDesIdPages = asPath.includes('give-hineni') || asPath.includes('the-upper-room');
    const desId = !noDesIdPages ? (designation || designationId || 'd4c15680-8d55-4d98-b538-1aa1e1e57ef2').trim() : '';
    const campId = campaignId?.trim() || '9f092f15-ba22-4fdf-90c7-996c4b97f9d4';
    const refCode = reference_code || 'STNETDON';
    // const embedId = 'ea55e7ce-0639-4f97-926d-89e55b85982c';
    const embedIdAttributes = embedId
        ? {
              id: 'idonate-giving-form-container',
              'data-embed-id': embedId
          }
        : '';

    const paramsReplace = params.replace(/=/g, ':').replace(/&/g, ', data-').replace('?', 'data-');
    const paramsSplit = paramsReplace.split(/[:|,]/);
    let extraAttributes;
    for (let i = 0; i < paramsSplit.length; i += 2) {
        extraAttributes = {
            [paramsSplit[i].trim()]: paramsSplit[i + 1],
            ...extraAttributes
        };
    }

    // Test commit

    useEffect(() => {
        // remove existing script for idonate
        (navigator.userAgent.includes('Safari') || navigator.userAgent.includes('Firefox')) && setIsSafariBrowser(true);
        const iDonateSite = embedId ? 'https://apps.idonate.com/idonate-giving-form.js' : 'https://embed.idonate.com/idonate.js';
        const scripts = document.querySelectorAll('script');
        scripts.forEach(script => {
            if (script.src.includes(iDonateSite) && !script.dataset.type === 'goalMeter') {
                document.body.removeChild(script);
            }
        });
        // window?.idonatelib?.reset?.();
        const script = document.createElement('script');
        script.src = iDonateSite;
        script.async = true;
        document.body.appendChild(script);
        window?.initializeEmbeds?.();
        return () => {
            // document.body.removeChild(script);
        };
    }, []);

    // return (
    //     <IFrame
    //         src={`${process.env.NEXT_PUBLIC_WP}/give?designation=${desId}&campaign=${campId}&embed_id=${embedId}&reference_code=${refCode}${params ? '&' + params : ''}`}
    //         width="100%"
    //         className={cx([
    //             className,
    //             keepDefaultHeight && 'min-h-[1650px] xsm:min-h-[1550px] sm:min-h-[1325px]',
    //             staffPage && 'md:min-h-[1400px] lg:min-h-[1300px] 2xl:min-h-[1200px]'
    //         ])}
    //     />
    // );

    return (
        <>
            {/* <Script src={embedId ? 'https://apps.idonate.com/idonate-giving-form.js' : 'https://embed.idonate.com/idonate.js'} /> */}
            {(router?.query?.uri?.length > 0 || safariBrowser) && (
                <div className="dark:bg-[#343434]">
                    <div data-idonate-embed={campId} data-designation={desId} data-reference_code={refCode} {...embedIdAttributes} {...extraAttributes}></div>
                </div>
            )}
        </>
    );
}
