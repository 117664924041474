import React from 'react';
import Image from 'next/image';
import cx from 'classnames';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from './FullwidthSlider.module.sass';
import SubHeader from '@/components/SubHeader';
import Container from '@/components/Container';
import SpacingLayout from '@/components/SpacingLayout';
import SubCTA from '@/components/SubCTA';
import LinkContainer from '@/components/SubCTA/LinkContainer';
import useWindowSize from '@/hooks/useWindowSize';

export default function FullwidthSlider(props) {
    const { sliderLayout, subLayout, subHeader } = props;
    const sliding = sliderLayout?.length > 1;
    const size = useWindowSize();

    const ButtonGroup = ({ next, previous }) => {
        if (!sliding) return null;
        return (
            <div className={`${styles.carouselButtonGroup} pointer-events-none absolute top-0 flex h-full w-full flex-row justify-between`}>
                <button aria-label="Go to previous slide" className={`${styles.arrowLeft && styles.arrowLeft} relative left-[-1%] md:left-[20px]`} onClick={() => previous()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                    </svg>
                </button>
                <button aria-label="Go to next slide" className={`${styles.arrowRight && styles.arrowRight} relative right-[-1%] md:right-[52px]`} onClick={() => next()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                </button>
            </div>
        );
    };

    const CustomDot = ({ index, onClick, active, children }) => {
        return (
            <li>
                <button
                    key={index}
                    onClick={e => {
                        onClick();
                        e.preventDefault();
                    }}
                    aria-label="Go to slide"
                    className={cx('custom-dot mx-1 h-2 w-2 rounded-full bg-gray', !active && 'opacity-30')}>
                    {children}
                </button>
            </li>
        );
    };

    const TextContainer = ({ children, content, textLocation, color, callToAction, linkAlignment, linkContainerCssClasses }) => {
        return (
            <div className={`relative h-full w-full text-${color}`}>
                <div className="absolute z-10 h-full w-full">
                    <div className="mx-auto h-full w-4/5 max-w-[1140px]">
                        <div className={`flex h-full flex-col justify-center items-${textLocation}`}>
                            <div className={`max-w-[385px]`}>
                                <div className="content-container" dangerouslySetInnerHTML={{ __html: content }} />
                                {callToAction?.length > 0 && (
                                    <LinkContainer direction={linkAlignment} className={linkContainerCssClasses}>
                                        {callToAction?.map((singleCta, index) => {
                                            return (
                                                <SubCTA
                                                    key={index}
                                                    type={singleCta?.ctaDisplay}
                                                    href={singleCta?.ctaLink?.url}
                                                    target={singleCta?.ctaTarget}
                                                    linkTarget={singleCta?.ctaLink?.target}
                                                    text={singleCta?.ctaLink?.title}
                                                    bgColor={singleCta?.ctaBgColor}
                                                    arrowSize="large"
                                                    textColor={singleCta?.ctaTextColor || 'white'}
                                                    className={singleCta?.ctaCssClassesCssSafelist}
                                                />
                                            );
                                        })}
                                    </LinkContainer>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {children}
            </div>
        );
    };

    return (
        <Container className="overflow-hidden" width={subLayout?.contentWidth} customWidth={subLayout?.customWidth}>
            <SpacingLayout contentWidth={subLayout?.contentWidth} defaultSpacing={subLayout?.defaultSpacing} className={subLayout?.layoutCssSafelist}>
                <SubHeader {...subHeader} />
                <div className="relative">
                    <Carousel
                        sliderClass={`react-fullwidth-slider h-full`}
                        swipeable={sliding}
                        draggable={sliding}
                        deviceType={'allScreens'}
                        showDots={false}
                        customDot={<CustomDot />}
                        infinite={true}
                        autoPlaySpeed={5000}
                        keyBoardControl={true}
                        containerClass={``}
                        arrows={false}
                        ssr={true}
                        customButtonGroup={<ButtonGroup />}
                        customTransition={'transform 800ms ease-in-out'}
                        transitionDuration={800}
                        renderButtonGroupOutside={true}
                        renderDotsOutside={true}
                        dotListClass="relative top-0"
                        autoPlay={false}
                        centerMode={false}
                        partialVisible={true}
                        itemClass="m-0 flex justify-center"
                        slidesToSlide={1}
                        responsive={{
                            allScreens: {
                                breakpoint: {
                                    max: 3000,
                                    min: 0
                                },
                                items: 1
                            }
                        }}>
                        {sliderLayout.map((slide, index) => {
                            const { slideContent, desktopImage, mobileImage, desktopTextLocation, textColor, subcta } = slide;
                            const { callToAction, linkAlignment, linkContainerCssClassCssSafelist } = subcta;
                            const { sourceUrl, altText } = desktopImage || {};
                            const { sourceUrl: mobileSourceUrl, altText: mobileAltText } = mobileImage || {};
                            return (
                                <React.Fragment key={index}>
                                    {desktopImage && size.width >= 768 && (
                                        <div className="fullwidth-slider-desktop-image-container hidden md:block">
                                            <TextContainer
                                                key={index}
                                                content={slideContent}
                                                textLocation={desktopTextLocation}
                                                color={textColor}
                                                linkAlignment={linkAlignment}
                                                callToAction={callToAction}
                                                linkContainerCssClasses={linkContainerCssClassCssSafelist}>
                                                <div className="max-h-[1000px] max-w-[2000px] 2xl:min-h-[600px] 5xl:h-[90vh]">
                                                    <Image alt={altText} src={sourceUrl} width="2200" height="1127" className="object-cover object-center" />
                                                </div>
                                            </TextContainer>
                                        </div>
                                    )}
                                    {mobileImage && size.width < 768 && (
                                        <div className="fullwidth-slider-mobile-image-container flex w-full md:hidden">
                                            <Image
                                                alt={mobileAltText}
                                                src={mobileSourceUrl}
                                                width="900"
                                                height="1310"
                                                className="h-[140vw] max-h-[85vh] w-full object-cover object-center"
                                            />
                                            <div className="absolute bottom-0 w-full px-7 pb-7 md:px-0">
                                                <div
                                                    className={cx('content-container text-center md:text-left', `text-${textColor}`)}
                                                    dangerouslySetInnerHTML={{ __html: slideContent }}
                                                />
                                                {callToAction?.length > 0 && (
                                                    <LinkContainer direction={linkAlignment} className={linkContainerCssClassCssSafelist}>
                                                        {callToAction?.map((singleCta, index) => {
                                                            return (
                                                                <SubCTA
                                                                    key={index}
                                                                    type={singleCta?.ctaDisplay}
                                                                    href={singleCta?.ctaLink?.url}
                                                                    target={singleCta?.ctaTarget}
                                                                    linkTarget={singleCta?.ctaLink?.target}
                                                                    text={singleCta?.ctaLink?.title}
                                                                    bgColor={singleCta?.ctaBgColor}
                                                                    arrowSize="large"
                                                                    textColor={singleCta?.ctaTextColor || 'white'}
                                                                    className={cx(singleCta?.ctaCssClassesCssSafelist, 'mx-auto')}
                                                                />
                                                            );
                                                        })}
                                                    </LinkContainer>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Carousel>
                </div>
            </SpacingLayout>
        </Container>
    );
}
